import { useContext } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import sprite from 'assets/svg/sprite.svg';

const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <svg style={{ fill: theme === 'dark-theme' ? 'white' : 'black' }} width='20' height='20' onClick={toggleTheme}>
      <use href={theme === 'dark-theme' ? sprite + '#icon-sun' : sprite + '#icon-dark-mode'}></use>
    </svg>
  );
}

export default ThemeSwitcher;
