import { getErrorMessage } from 'utils/errors.utility';
import { useNotification } from 'contexts/NotificationContext';

export const useHandleError = () => {
  const { showError } = useNotification();

  return (error: any) => {
    if (error.response && error.response.status !== 401) {
      return showError(getErrorMessage(error));
    }
  };
};
