import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import 'assets/css/components/buttons.css';
import { TableFilterButtonsProps } from './types';

const TableFilterButtons: React.FC<TableFilterButtonsProps> = ({ filterButtons, onFilter, isActive }) => {

  return (
    <ButtonGroup className="trbewc-button-group">
      {filterButtons.map((button, index) => (
        <Button
          key={index}
          className={`trbewc-page__button trbewc-page__button-group ${isActive === button.value ? 'active' : ''}`}
          onClick={() => onFilter(button.value)}
        >
          {button.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}

export default TableFilterButtons;
