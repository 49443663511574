import React from 'react';
import Dropdown from 'components/Dropdown/Dropdown';
import Search from 'components/Search/Search';
import { TableControlPanelProps } from './types';
import TableFilterButtons from 'components/Table/TableFilterButtons';

const TableControlPanel: React.FC<TableControlPanelProps> = ({
                                                               dropdownConfigs = [],
                                                               isSearchVisible,
                                                               isDropdownVisible,
                                                               isFilterButtonsVisible,
                                                               searchConfigs,
                                                               filterConfigs,
                                                              }) => {
  const renderDropdown = (config: typeof dropdownConfigs[number], index: number) => (
    <Dropdown
      key={index}
      label={config.label}
      options={config.options}
      title={config.title}
      menuStyle={config.menuStyle}
      headerStyle={config.headerStyle}
      onFilter={config.onFilter}
    />
  );

  return (
    <div className='trbewc-table-page__nav-container'>
      {isFilterButtonsVisible && filterConfigs && (
        <div className='trbewc-table-page__nav-container-buttons'>
          <TableFilterButtons filterButtons={filterConfigs.filterButtons} onFilter={filterConfigs.onFilter}
                              isActive={filterConfigs.isActive}/>
        </div>
      )}
      {isDropdownVisible && dropdownConfigs.length > 0 && renderDropdown(dropdownConfigs[0], 0)}
      {isSearchVisible && searchConfigs && (
        <div className='trbewc-table-page__nav-container-search'>
          <Search
            onSearch={searchConfigs.onSearch}
            onReset={searchConfigs.onReset}
            values={searchConfigs.values}
            searchableFields={searchConfigs.searchableFields}
            displayFields={searchConfigs.displayFields}
          />
        </div>
      )}
      {isDropdownVisible && dropdownConfigs.slice(1).map((config, index) => renderDropdown(config, index + 1))}
    </div>
  );
};

export default TableControlPanel;
