import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import useThemeStyles from 'hooks/useThemeStyles';
import ThemeContext from 'contexts/ThemeContext';
import { AuthenticatedLayoutProps } from './types';
import NavBar from 'components/NavBar/NavBar';

const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({ children }) => {
  const { theme } = useContext(ThemeContext);
  const styles = useThemeStyles();
  return (
    <div className={theme} style={styles.container}>
      <header>
        <NavBar/>
      </header>
      <Container fluid className="flex-grow-1">
        {children}
      </Container>
    </div>
  );
};

export default AuthenticatedLayout;
