import React, { useEffect, useState } from 'react';
import Table from 'components/Table/Table';
import administrationSessionsConfigs from './admin-sessions-page-configs.json';
import { ResponseUserSession2 } from '../Settings/types';
import useThemeStyles from 'hooks/useThemeStyles';
import { useNotification } from 'contexts/NotificationContext';
import {
  deleteAllSuperadminSessions,
  deleteSuperadminSession,
  getSuperadminSessions,
  renewSuperadminSession
} from 'api/framework';
import { getErrorMessage } from 'utils/errors.utility';
import useTableFilter from 'hooks/useTableFilter';
import TableControlPanel from 'components/TableControlPanel/TableControlPanel';

const AdministrationSessionsPage: React.FC = () => {
  const styles = useThemeStyles();
  const { showSuccess, showError } = useNotification();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false);
  const [sessions, setSessions] = useState<ResponseUserSession2[]>([]);
  const {
    filteredValues,
    handleFilter,
    activeFilter
  } = useTableFilter(sessions, ['who', 'where', 'when', 'what', 'activity', 'ipAddr'], administrationSessionsConfigs.filterOptions);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSuperadminSessions();
        setSessions(response.data.activeSessions);
      } catch (error) {
        showError(getErrorMessage(error));
      }
    };
    fetchData();
  }, [showError]);

  const handleSearch = (results: any[]) => {
    setSearchResults(results);
    setSearchPerformed(true);
  };

  const resetSearch = () => {
    setSearchPerformed(false);
  };

  const searchableFields = administrationSessionsConfigs.headers
    .filter(header => header.searchable)
    .map(header => header.fieldName);

  const displayFields = administrationSessionsConfigs.headers.map(header => header.fieldName);

  const displayValues = searchPerformed ? searchResults : filteredValues;

  const searchConfigs = {
    onSearch: handleSearch,
    onReset: () => resetSearch(),
    values: sessions,
    searchableFields,
    displayFields
  };

  const deleteAllSessions = async () => {
    try {
      await deleteAllSuperadminSessions();
      const response = await getSuperadminSessions();
      setSessions(response.data.activeSessions);
      showSuccess('Sessions deleted successfully');
    } catch (error) {
      showError(getErrorMessage(error));
    }
  }

  const actionHandlers: { [key: string]: (row: ResponseUserSession2) => void } = {
    reload: async (row: ResponseUserSession2) => {
      try {
        await renewSuperadminSession(row.id);
        const response = await getSuperadminSessions();
        const updatedSessions = sessions.map((session) =>
          session.id === row.id ? { ...session, ...response.data.activeSessions.find((s: ResponseUserSession2) => s.id === row.id) } : session
        );
        setSessions(updatedSessions);
        showSuccess('Session reloaded successfully');
      } catch (error) {
        showError(getErrorMessage(error));
      }
    },
    delete: async (row: ResponseUserSession2) => {
      if (row.current) {
        return;
      }
      try {
        await deleteSuperadminSession(row.id);
        const response = await getSuperadminSessions();
        setSessions(response.data.activeSessions);
        showSuccess('Session deleted successfully');
      } catch (error) {
        showError(getErrorMessage(error));
      }
    }
  };

  const actions = administrationSessionsConfigs.actions.map(action => ({
    label: action.label,
    handler: actionHandlers[action.type],
    icon: action.icon,
    getClassName: (row: ResponseUserSession2) => action.type === 'delete' && row.current ? 'disabled' : ''
  }));

  return (
    <div className='trbewc-table-page'>
      <div className="d-flex justify-content-between">
        <h4 className='trbewc-userbar-settings__title'>Sessions</h4>
        <button onClick={deleteAllSessions} className="trbewc-page__button">Terminate All</button>
      </div>
      <div style={styles.dividerStyle}></div>
      <TableControlPanel isFilterButtonsVisible={true} isSearchVisible={true} searchConfigs={searchConfigs}/>
      <div className='trbewc-table-page__body-container'>
        <Table
          headers={administrationSessionsConfigs.headers}
          values={displayValues}
          filterOptions={administrationSessionsConfigs.filterOptions}
          sortOptions={[]}
          handleFilter={handleFilter}
          activeFilter={activeFilter}
          actions={actions}
        />
      </div>
    </div>
  );
};

export default AdministrationSessionsPage;
