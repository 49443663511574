import React, { useEffect, useState } from 'react';
import Table from 'components/Table/Table';
import useThemeStyles from 'hooks/useThemeStyles';
import { getInvitations } from 'api/framework';
import tableConfigs from './invitation-page-configs.json';
import { useNavigate } from 'react-router-dom';
import { ResponseInvitationCode, ResponseInvitationCodes } from 'api/types';
import useTableFilter from 'hooks/useTableFilter';
import TableControlPanel from 'components/TableControlPanel/TableControlPanel';
import { getCurrentClientUser } from 'services/localStorageService';

const InvitationsPage: React.FC = () => {
  const styles = useThemeStyles();
  const navigate = useNavigate();

  const [tableValues, setTableValues] = useState<ResponseInvitationCode[]>([]);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false);
  const [activeRowId, setActiveRowId] = useState<string | null>(null);

  const userAuthorities = getCurrentClientUser()?.authorities;

  useEffect(() => {
    const fetchData = async () => {
      const response: ResponseInvitationCodes = await getInvitations();
      setTableValues(response.invitationCodes);
    };
    fetchData();
  }, []);

  const { filteredValues, handleFilter, activeFilter } = useTableFilter(tableValues, ['usage']);

  const handleSearch = (results: any[]) => {
    setSearchResults(results);
    setSearchPerformed(true);
  };

  const resetSearch = () => {
    setSearchPerformed(false);
  };

  const searchableFields = tableConfigs.headers
    .filter(header => header.searchable)
    .map(header => header.fieldName);

  const displayFields = tableConfigs.headers.map(header => header.fieldName);
  const displayValues = searchPerformed ? searchResults : filteredValues;

  const searchConfigs = {
    onSearch: handleSearch,
    onReset: () => resetSearch(),
    values: tableValues,
    searchableFields,
    displayFields
  };

  const filterConfigs = {
    filterButtons: tableConfigs.filterButtons,
    onFilter: handleFilter,
    isActive: activeFilter,
  };

  const actionHandlers: { [key: string]: (row: any) => void } = {
    copy: (row) => {
      navigator.clipboard.writeText(row.value).then(() => {
        setActiveRowId(row.id);
        setTimeout(() => {
          setActiveRowId(null);
        }, 1000);
      });
    }
  };

  const actions = tableConfigs.actions.map(action => ({
    label: action.label,
    handler: actionHandlers[action.type],
    icon: (row: any) => row.id === activeRowId ? 'icon-done' : action.icon
  }));

  return (
    <div className='trbewc-table-page'>
      <div className="d-flex justify-content-between mb-3">
        <h4 className='trbewc-userbar-settings__title'>Invitations</h4>
        {userAuthorities && userAuthorities.includes('invitationCode:write') && (
          <button onClick={() => navigate('new')} className='trbewc-page__button' type="submit">New</button>
        )}
      </div>
      <div style={styles.dividerStyle}></div>
      <TableControlPanel isFilterButtonsVisible={true} isSearchVisible={true} searchConfigs={searchConfigs}
                         filterConfigs={filterConfigs}/>
      <div className='trbewc-table-page__body-container'>
        <Table
          headers={tableConfigs.headers}
          values={displayValues}
          rowsPerPage={5}
          filterOptions={tableConfigs.filterOptions}
          sortOptions={tableConfigs.sortOptions}
          actions={actions}
        />
      </div>
    </div>
  );
};

export default InvitationsPage;
