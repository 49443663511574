import React from 'react';
import sprite from 'assets/svg/sprite.svg';
import { useNotification } from 'contexts/NotificationContext';

const Notification: React.FC = () => {
  const { notification, hideNotification } = useNotification();

  return (
    <div className={`trbewc-notification trbewc-notification--${notification.type}`}>
      {notification.message}
      <button
        className={`trbewc-button trbewc-notification__close-button trbewc-notification__close-button--${notification.type}`}
        onClick={hideNotification}
      >
        <svg className={`trbewc-notification__close-icon--${notification.type}`} width={13} height={13}>
          <use href={sprite + '#icon-close'}></use>
        </svg>
      </button>
    </div>
  );
};

export default Notification;
