import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import 'react-bootstrap/dist/react-bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/index.css';

const el = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(el);

root.render(
  <App/>
);

