import React, { useEffect, useState } from 'react';
import useThemeStyles from 'hooks/useThemeStyles';
import { ResponseUserSession2 } from './types';
import Table from 'components/Table/Table';
import tableConfigs from './sessions-page-configs.json';
import { deleteSession, getSessions, renewSession } from 'api/framework';
import { useNotification } from 'contexts/NotificationContext';
import { useHandleError } from 'hooks/useHandleError';

const SessionsPage: React.FC = () => {
  const styles = useThemeStyles();
  const handleError = useHandleError();
  const { showSuccess } = useNotification();
  const [sessions, setSessions] = useState<ResponseUserSession2[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getSessions();
      setSessions(response.data.sessions);
    };
    fetchData();
  }, []);

  const actionHandlers: { [key: string]: (row: ResponseUserSession2) => void } = {
    reload: async (row: ResponseUserSession2) => {
      try {
        await renewSession(row.id);
        const response = await getSessions();
        const updatedSessions = sessions.map((session) =>
          session.id === row.id ? { ...session, ...response.data.sessions.find((s: ResponseUserSession2) => s.id === row.id) } : session
        );
        setSessions(updatedSessions);
        showSuccess('Session reloaded successfully');
      } catch (error) {
        handleError(error);
      }
    },
    delete: async (row: ResponseUserSession2) => {
      if (row.current) {
        return;
      }
      try {
        await deleteSession(row.id);
        const response = await getSessions();
        setSessions(response.data.sessions);
        showSuccess('Session deleted successfully');
      } catch (error) {
        handleError(error);
      }
    }
  };

  const actions = tableConfigs.actions.map(action => ({
    label: action.label,
    handler: actionHandlers[action.type],
    icon: action.icon,
    getClassName: (row: ResponseUserSession2) => action.type === 'delete' && row.current ? 'disabled' : ''
  }));

  return (
    <div className='trbewc-table-page'>
      <h4 className='trbewc-userbar-settings__title'>Sessions</h4>
      <div style={styles.dividerStyle}></div>
      <div className='trbewc-table-page__nav-container'>
        <Table
          headers={tableConfigs.headers}
          values={sessions}
          filterOptions={[]}
          sortOptions={tableConfigs.sortOptions}
          actions={actions}
        />
      </div>
    </div>
  );
};

export default SessionsPage;
