import React, { createContext, useContext, useReducer, ReactNode, useCallback } from 'react';
import { NotificationState, NotificationAction, NotificationContextProps } from 'contexts/types';

const initialState: NotificationState = { visible: false, type: '', message: '' };

const notificationReducer = (state: NotificationState, action: NotificationAction): NotificationState => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return { visible: true, type: action.payload?.type || '', message: action.payload?.message || '' };
    case 'HIDE_NOTIFICATION':
      return { ...state, visible: false };
    default:
      return state;
  }
};

export const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const useNotification = (): NotificationContextProps => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [notification, dispatch] = useReducer(notificationReducer, initialState);

  const showNotification = useCallback((type: string, message: string) => {
    dispatch({ type: 'SHOW_NOTIFICATION', payload: { visible: true, type, message } });
  }, []);

  const hideNotification = useCallback(() => {
    dispatch({ type: 'HIDE_NOTIFICATION' });
  }, []);

  const showSuccess = useCallback((message: string) => {
    showNotification('success', message);
  }, [showNotification]);

  const showWarning = useCallback((message: string) => {
    showNotification('warning', message);
  }, [showNotification]);

  const showError = useCallback((message: string) => {
    showNotification('error', message);
  }, [showNotification]);

  const showInfo = useCallback((message: string) => {
    showNotification('info', message);
  }, [showNotification]);

  return (
    <NotificationContext.Provider
      value={{ notification, showNotification, hideNotification, showSuccess, showWarning, showError, showInfo }}>
      {children}
    </NotificationContext.Provider>
  );
};
