import React, { createContext, useContext, useState, useEffect, ReactNode, useRef } from 'react';
import { Client, Frame, Message } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import axiosClient from 'axiosClient/axiosClient';
import { applicationConstants } from 'constants/application.constants';
import { WebSocketContextType, WebSocketMessage } from 'contexts/types';
import { useAuth } from 'contexts/AuthContext';
import cookieUtility from 'utils/cookie.utility';

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

const stompConfig = {
  stompEndpoint: `${applicationConstants.baseServerURL}${applicationConstants.WEBSOCKETS_CONFIGS.ENDPOINT}`
};

export const WebSocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [messages, setMessages] = useState<WebSocketMessage[]>([]);
  const [connected, setConnected] = useState<boolean>(false);
  const { isAuthenticated } = useAuth();
  const clientRef = useRef<Client | null>(null);

  const initializeWebSocket = (csrfToken: string) => {
    if (clientRef.current) {
      clientRef.current.deactivate();
    }

    const headers = {
      [applicationConstants.CSRF_CONFIGS.HEADER_NAME]: csrfToken,
    };

    const newClient = new Client({
      connectHeaders: headers,
      webSocketFactory: function () {
        return new SockJS(stompConfig.stompEndpoint);
      },
      reconnectDelay: applicationConstants.WEBSOCKETS_CONFIGS.RECONNECT_DELAY,
      heartbeatIncoming: applicationConstants.WEBSOCKETS_CONFIGS.HEARTBEAT_INCOMING,
      heartbeatOutgoing: applicationConstants.WEBSOCKETS_CONFIGS.HEARTBEAT_OUTGOING,
      onConnect: (frame) => {
        newClient.subscribe(applicationConstants.WEBSOCKETS_CONFIGS.DESTINATIONS.ACCOUNTS, (message: Message) => {
          setMessages((prevMessages) => [...prevMessages, JSON.parse(message.body)]);
        });
        setConnected(true);
      },
      onDisconnect: (frame) => {
        console.log('WS.onDisconnect. Frame: ' + frame);
        setConnected(false);
      },
      onStompError: (frame: Frame) => {
        console.error('WS.Broker reported error: ' + frame.headers['message']);
        console.error('WS.Additional details: ' + frame.body);
      }
    });

    clientRef.current = newClient;
    newClient.activate();
  };

  useEffect(() => {
    if (isAuthenticated) {
      axiosClient.get(`${applicationConstants.baseServerURL}/system/csrf`).then((tokenValue) => {
        const csrfTokenValue = tokenValue.data;
        cookieUtility.setCookie(applicationConstants.CSRF_CONFIGS.TOKEN_KEY, csrfTokenValue, applicationConstants.ONE_HOUR_IN_MILLISECONDS);
        initializeWebSocket(csrfTokenValue);
      });
    } else if (clientRef.current) {
      clientRef.current.deactivate();
      clientRef.current = null;
      setConnected(false);
    }
  }, [isAuthenticated]);

  return (
    <WebSocketContext.Provider value={{ messages, connected }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocketContext must be used within a WebSocketProvider');
  }
  return context;
};
