import React, { useState, useEffect } from 'react';
import useThemeStyles from 'hooks/useThemeStyles';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { resetServer } from 'api/framework';
import { getErrorMessage } from 'utils/errors.utility';
import { useNotification } from 'contexts/NotificationContext';
import { useWebSocketContext } from 'contexts/WebSocketContext';

const AdministrationServerPage = () => {
  const styles = useThemeStyles();
  const { showError } = useNotification();
  const { messages } = useWebSocketContext();

  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [progressDescription, setProgressDescription] = useState<string>('');
  const [isResetting, setIsResetting] = useState<boolean>(false);

  useEffect(() => {
    const latestMessage = messages[messages.length - 1];

    if (latestMessage) {
      const status = latestMessage.attributes?.status;
      if (status) {
        setProgressPercentage(status.percentage?.percentage || 0);
        setProgressDescription(status.description || '');
      }
    }
  }, [messages]);

  const handleResetServer = async () => {
    try {
      setIsResetting(true);
      await resetServer();
    } catch (error) {
      showError(getErrorMessage(error));
    } finally {
      setIsResetting(false);
    }
  }

  return (
    <div>
      <h4 className='trbewc-userbar-settings__title'>Reset Server</h4>
      <div style={styles.dividerStyle}></div>
      <div className="trbewc-server__progress-bar">
        <div className="trbewc-server__progress-bar-wrapper">
          <ProgressBar animated now={progressPercentage} label={`${progressPercentage}%`}/>
        </div>
        <p className="trbewc-server__progress-bar-text">{progressDescription}</p>
        <button
          onClick={handleResetServer}
          className="trbewc-page__button"
          disabled={isResetting}>
          Reset Server
        </button>
      </div>
    </div>
  );
}

export default AdministrationServerPage;
