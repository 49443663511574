import React from 'react';
import { Formik, Field, Form as FormikForm, FieldProps } from 'formik';
import { validationSchemaProfile } from 'validation/validationSchemas';
import Form from 'react-bootstrap/Form';
import useThemeStyles from 'hooks/useThemeStyles';
import { useAuth } from 'contexts/AuthContext';
import timezones from 'configs/timezones.json';
import { RequestUserUpdate1 } from 'api/types';
import { Option } from 'foundation/types';
import InputErrorMessage from 'components/Errors/InputErrorMessage';
import { useNotification } from 'contexts/NotificationContext';
import { useHandleError } from 'hooks/useHandleError';
import sprite from 'assets/svg/sprite.svg';

const ProfilePage: React.FC = () => {
  const styles = useThemeStyles();
  const { executeUpdateProfileRequest, user } = useAuth();
  const { showSuccess, showError } = useNotification();
  const handleError = useHandleError();

  const handleSubmit = async (values: RequestUserUpdate1, { setSubmitting }: {
    setSubmitting: (isSubmitting: boolean) => void
  }) => {
    try {
      await executeUpdateProfileRequest(values);
      showSuccess('Profile updated successfully');
    } catch (error: any) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='trbewc-profile-page'>
      <div className={'trbewc-page-content'}>
        <Formik
          initialValues={{
            name: user?.name || '',
            email: user?.email || '',
            zoneId: user?.zoneId || timezones[0].id
          }}
          validationSchema={validationSchemaProfile}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, handleChange, values }) => (
            <FormikForm>
              <h4 className='trbewc-userbar-settings__title'>Public profile</h4>
              <div style={styles.dividerStyle}></div>
              <Form.Group className="mb-3 position-relative trbewc-profile-page__form-group">
                <Form.Label htmlFor="name" style={styles.labelStyle}>Name</Form.Label>
                <Field
                  id='name'
                  name='name'
                  type='text'
                  className="trbewc-profile-page__input form-control"
                  onChange={handleChange}
                  value={values.name}
                />
                <InputErrorMessage name="name"/>
              </Form.Group>
              <Form.Group className="mb-3 position-relative trbewc-profile-page__form-group">
                <Form.Label htmlFor="email" style={styles.labelStyle}>Email</Form.Label>
                <Field
                  id='email'
                  name='email'
                  type='email'
                  className="trbewc-profile-page__input form-control"
                  onChange={handleChange}
                  value={values.email}
                />
                <InputErrorMessage name="email"/>
              </Form.Group>
              <Form.Group className="mb-3 position-relative trbewc-profile-page__form-group">
                <Form.Label htmlFor="zoneId" style={styles.labelStyle}>Timezone</Form.Label>
                <Field name="zoneId">
                  {({ field }: FieldProps) => (
                    <Form.Select
                      {...field}
                      id="zoneId"
                      className="trbewc-profile-page__input form-control"
                      onChange={handleChange}
                      value={values.zoneId}
                    >
                      {timezones.map((tz: Option) => (
                        <option key={tz.id} value={tz.id}>
                          {tz.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </Field>
                <svg
                  className='trbewc-dropdown-icon'
                  width={15} height={15}>
                  <use href={sprite + '#icon-dropdown'}></use>
                </svg>
                <InputErrorMessage name="zoneId"/>
              </Form.Group>
              <button className='trbewc-page__button' type="submit" disabled={isSubmitting}>
                Save
              </button>
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProfilePage;
