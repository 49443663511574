import React from 'react';
import { PaginationProps } from './types';
import sprite from 'assets/svg/sprite.svg';
import 'assets/css/components/pagination.css';

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange, itemsPerPage, onItemsPerPageChange }) => {
  if (totalPages === 1) {
    return null;
  }

  const handlePageChange = (page: number) => () => {
    onPageChange(page);
  };

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onItemsPerPageChange(Number(event.target.value));
  };

  const createPageNavigationButton = (label: string, onClick: () => void, disabled: boolean, icon: string) => (
    <button
      className='trbewc-page__button-pagination'
      onClick={onClick}
      disabled={disabled}
    >
      {label}
      <svg className="trbewc-button__icon-menu" width={15} height={20}>
        <use href={`${sprite}#${icon}`}></use>
      </svg>
    </button>
  );

  return (
    <div className='trbewc-pagination'>
      {createPageNavigationButton('First', handlePageChange(1), currentPage === 1, 'icon-previous2')}
      {createPageNavigationButton('Previous', handlePageChange(currentPage - 1), currentPage === 1, 'icon-backward2')}
      {totalPages <= 3 ? (
        Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={handlePageChange(index + 1)}
            className={currentPage === index + 1 ? 'trbewc-page__active-number' : 'trbewc-page__button-number'}
          >
            {index + 1}
          </button>
        ))
      ) : (
        <>
          {currentPage > 1 && (
            <button
              onClick={handlePageChange(currentPage - 1)}
              className='trbewc-page__button-number'
            >
              {currentPage - 1}
            </button>
          )}
          <button
            onClick={handlePageChange(currentPage)}
            className='trbewc-page__active-number'
          >
            {currentPage}
          </button>
          {currentPage < totalPages && (
            <button
              onClick={handlePageChange(currentPage + 1)}
              className='trbewc-page__button-number'
            >
              {currentPage + 1}
            </button>
          )}
        </>
      )}
      {createPageNavigationButton('Next', handlePageChange(currentPage + 1), currentPage === totalPages, 'icon-forward3')}
      {createPageNavigationButton('Last', handlePageChange(totalPages), currentPage === totalPages, 'icon-next2')}
      <div className="position-relative">
        <svg
          className='trbewc-dropdown-pagination-icon'
          width={15} height={15}>
          <use href={sprite + '#icon-dropdown'}></use>
        </svg>
        <select className='trbewc-items-per-page' value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={10}>10/page</option>
          <option value={25}>25/page</option>
          <option value={50}>50/page</option>
          <option value={100}>100/page</option>
        </select>
      </div>
    </div>
  );
};

export default Pagination;
