import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useThemeStyles from 'hooks/useThemeStyles';
import { useAuth } from 'contexts/AuthContext';
import { validationSchemaLogin } from '../../validation/validationSchemas';
import { LoginFormProps } from './types';
import { Formik, Field, Form as FormikForm, FormikHelpers } from 'formik';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/components/forms.css';
import sprite from 'assets/svg/sprite.svg';
import InputErrorMessage from 'components/Errors/InputErrorMessage';

const LoginPage: React.FC = () => {
  const styles = useThemeStyles();
  const navigate = useNavigate();
  const { executeLoginRequest, isAuthenticated } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseError = () => {
    setError('');
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (values: LoginFormProps, { setSubmitting }: FormikHelpers<LoginFormProps>) => {
    try {
      setError('');
      await executeLoginRequest(values.username, values.password);
      navigate('/');
    } catch (error: any) {
      setError(error.message);
    }
    setSubmitting(false);
  };

  return (
    <div className='trbewc-form' style={styles.content}>
      {error && (
        <div className="trbewc-form-notification">
          {error}
          <button
            style={styles.buttonCloseStyle}
            className="trbewc-button trbewc-button-close--error"
            onClick={handleCloseError}
          >
            <svg className="trbewc-button__icon-close--error" width={13} height={13}>
              <use href={sprite + '#icon-close'}></use>
            </svg>
          </button>
        </div>
      )}
      <div className='trbewc-form__form-container' style={styles.formContainer}>
        <Formik
          initialValues={{ username: '', password: '' }}
          validationSchema={validationSchemaLogin}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, handleChange, handleBlur, values }) => (
            <FormikForm>
              <Form.Group className="mb-3 position-relative" controlId="formBasicUsername">
                <Form.Label style={styles.labelStyle}>Username</Form.Label>
                <Field
                  className="trbewc-form__form-control form-control"
                  type="text"
                  name="username"
                  style={styles.inputStyle}
                  onChange={handleChange}
                  value={values.username}
                />
                <InputErrorMessage name="username" />
              </Form.Group>
              <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                <Form.Label style={styles.labelStyle}>Password</Form.Label>
                <div className='trbewc-form__form-input'>
                  <Field
                    className="trbewc-form__form-control form-control"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    style={{ ...styles.inputStyle }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <svg className="trbewc-form__form-icon" onClick={togglePasswordVisibility} width='15' height='15'>
                    <use
                      href={sprite + (showPassword ? '#icon-eye' : '#icon-eye-blocked')}
                    ></use>
                  </svg>
                </div>
                <InputErrorMessage name="password" />
              </Form.Group>
              <button className="trbewc-form__button" type="submit" disabled={isSubmitting}>
                {isSubmitting && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Login
              </button>
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginPage;
