import { useState, useEffect, useRef } from 'react';
import MiniSearch, { SearchResult } from 'minisearch';

interface DataItem {
  [key: string]: any;
}

const useSearch = (data: DataItem[], searchableFields: string[], displayFields: string[]) => {
  const [results, setResults] = useState<SearchResult[]>([]);
  const [query, setQuery] = useState<string>('');
  const miniSearchRef = useRef<MiniSearch<DataItem> | null>(null);

  useEffect(() => {
    const miniSearch = new MiniSearch<DataItem>({
      fields: searchableFields,
      storeFields: displayFields,
      searchOptions: {
        prefix: true
      }
    });

    miniSearch.addAll(data);
    miniSearchRef.current = miniSearch;
  }, [data, searchableFields, displayFields]);

  useEffect(() => {
    if (miniSearchRef.current) {
      const searchResults = miniSearchRef.current.search(query);
      setResults(searchResults);
    }
  }, [query]);

  const handleQueryChange = (newQuery: string) => {
    setQuery(newQuery);
  };

  return { results, search: handleQueryChange };
};

export default useSearch;
