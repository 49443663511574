import React, { useState, useEffect } from 'react';
import Pagination from 'components/Pagination/Pagination';
import { TableAction, TableProps } from './types';
import { Container } from 'react-bootstrap';
import sprite from 'assets/svg/sprite.svg';
import 'assets/css/components/tables.css';

const Table: React.FC<TableProps> = ({ headers, values, rowsPerPage = 10, filterOptions, actions, handleFilter, activeFilter }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'ascending' | 'descending' | null }>({
    key: '',
    direction: null
  });
  const [sortedValues, setSortedValues] = useState(values);
  const [itemsPerPage, setItemsPerPage] = useState(rowsPerPage);

  useEffect(() => {
    setSortedValues(values);
  }, [values]);

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  const totalPages = Math.ceil(values.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
  };

  const renderIcon = (action: TableAction, row: any) => {
    if (typeof action.icon === 'function') {
      return action.icon(row);
    }
    return action.icon;
  };

  const handleSort = (column: string) => {
    let direction = 'ascending';
    if (sortConfig.key === column && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: column, direction: direction as 'ascending' | 'descending' });

    const sorted = [...sortedValues].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortedValues(sorted);
  };

  const getIconFillColor = (label: string) => {
    if (activeFilter === 'Online' && label === 'Online') {
      return '#228336';
    }
    if (activeFilter === 'Offline' && label === 'Offline') {
      return '#f91919';
    }
    return 'gray';
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedRows = sortedValues.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className='container'>
      <Container className='trbewc-table'>
        <div className='trbewc-table__header'>
          <div className='trbewc-table__header-button-group'>
            {filterOptions.map((option) => (
              <button
                key={option.label}
                className={`trbewc-page__button-table ${activeFilter === option.label ? 'active' : ''}`}
                onClick={() => handleFilter && handleFilter(option.label)}
              >
                <svg className="trbewc-button__icon-menu" width={15} height={20} style={{ fill: getIconFillColor(option.label) }}>
                  <use href={sprite + '#' + option.icon}></use>
                </svg>
                <span className="trbewc-page__button-table-span">{option.label}</span>
              </button>
            ))}
          </div>
        </div>
        {!values || values.length === 0 ? (
          <div className="p-2">No data available</div>
        ) : (
          <div className='trbewc-table__body-container'>
            <table className='trbewc-table__body'>
              <thead className='trbewc-table__body-header'>
              <tr>
                {headers.map((header) => (
                  <th key={header.fieldName}>
                    <button
                      className={`${header.sortable ? 'trbewc-table__header-btn--sortable' : 'trbewc-table__header-btn'}`}
                      onClick={() => header.sortable && handleSort(header.fieldName)}
                    >
                      {header.columnName}
                      {header.sortable && sortConfig.key === header.fieldName && (
                        sortConfig.direction === 'ascending' ? (
                          <svg width={10} height={10}>
                            <use href={sprite + '#icon-arrow-up2'}></use>
                          </svg>
                        ) : (
                          <svg width={10} height={10}>
                            <use href={sprite + '#icon-arrow-down2'}></use>
                          </svg>
                        )
                      )}
                    </button>
                  </th>
                ))}
                {actions && <th>Actions</th>}
              </tr>
              </thead>
              <tbody>
              {selectedRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header) => (
                    <td key={header.fieldName}>
                      {typeof row[header.fieldName] === 'object'
                        ? JSON.stringify(row[header.fieldName])
                        : row[header.fieldName]}
                    </td>
                  ))}
                  {actions && (
                    <td>
                      {actions.map((action, actionIndex) => (
                        <svg
                          key={actionIndex}
                          onClick={() => action.type !== 'delete' && action.handler(row)}
                          className={`trbewc-button__icon ${action.getClassName ? action.getClassName(row) : ''}`}
                          width={15}
                          height={15}
                        >
                          <use href={sprite + '#' + renderIcon(action, row)}></use>
                        </svg>
                      ))}
                    </td>
                  )}
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        )}
      </Container>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </div>
  );
};

export default Table;
