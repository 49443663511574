import React from 'react'
import logo from 'assets/logo/logo.jpg'
import 'assets/css/components/logo.css'

const Logo = () => {
  return (
    <div className='trbewc-logo'>
      <img
        alt="logo"
        src={logo}
        className='trbewc-logo__image'
      />
    </div>
  )
}

export default Logo
