import { useContext, CSSProperties } from 'react';
import ThemeContext from 'contexts/ThemeContext';

const useThemeStyles = () => {
  const { themeVariables } = useContext(ThemeContext)

  const styles: { [key: string]: CSSProperties } = {
    container: {
      backgroundColor: themeVariables.mainBgColor,
      color: themeVariables.primaryTextColor,
      minHeight: '100vh'
    },
    header: {
      padding: '16px',
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '80vh'
    },
    formContainer: {
      backgroundColor: themeVariables.secondaryBgColor,
      padding: '16px',
      borderRadius: '8px',
      maxWidth: '300px',
      width: '100%',
      border: `0.5px solid ${themeVariables.primaryInputBorderColor}`
    },
    inputStyle: {
      backgroundColor: themeVariables.mainBgColor,
      color: themeVariables.primaryTextColor,
      outline: 'none',
      boxShadow: 'none',
      height: '35px',
      transition: 'border-color 0.3s ease'
    },
    inputSearchStyle: {
      backgroundColor: themeVariables.secondaryBgColor,
      color: themeVariables.primaryTextColor,
      outline: 'none',
      boxShadow: 'none',
      height: '32px',
      transition: 'border-color 0.3s ease'
    },
    labelStyle: {
      color: themeVariables.primaryTextColor,
      fontSize: '14px'
    },
    navbarStyle: {
      backgroundColor: themeVariables.secondaryBgColor,
      color: themeVariables.primaryTextColor,
      borderBottom: `0.5px solid ${themeVariables.primaryInputBorderColor}`,
      width: '100%',
      padding: '8px 24px',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    sidebarStyle: {
      backgroundColor: themeVariables.mainBgColor,
      color: themeVariables.primaryTextColor,
      width: '320px',
      border: `0.5px solid ${themeVariables.primaryInputBorderColor}`
    },
    dividerStyle: {
      margin: '8px 0',
      border: `0.5px solid ${themeVariables.primaryInputBorderColor}`
    },
    dividerDropdownStyle: {
      margin: '0',
      border: `0.5px solid ${themeVariables.primaryInputBorderColor}`
    },
    buttonIconStyle: {
      backgroundColor: themeVariables.mainBgColor,
      fill: themeVariables.secondaryTextColor,
      border: `0.5px solid ${themeVariables.primaryInputBorderColor}`,
      borderRadius: '6px',
      marginRight: '16px'
    },
    buttonCloseStyle: {
      fill: themeVariables.secondaryTextColor,
      border: 'none',
    },
    buttonCloseSuccessStyle: {
      fill: themeVariables.linkColor,
    },
    buttonNavbarStyle: {
      backgroundColor: themeVariables.mainBgColor,
      color: themeVariables.primaryTextColor,
      border: `0.5px solid ${themeVariables.primaryInputBorderColor}`,
      borderRadius: '6px',
      padding: '8px',
      cursor: 'pointer',
      fontSize: '14px',
    },
    iconStyle: {
      fill: themeVariables.secondaryColor,
      width: '14px',
      height: '20px',
    }
  }
  return styles
}

export default useThemeStyles;
