export const getCSSVariableValue = (variable: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable).trim()
}

export const getThemeVariables = (theme: 'light-theme' | 'dark-theme') => {
  return {
    primaryColor: getCSSVariableValue('--trbewc-primary-color'),
    secondaryColor: getCSSVariableValue('--trbewc-secondary-color'),
    mainBgColor: getCSSVariableValue('--trbewc-main-bg-color'),
    secondaryBgColor: getCSSVariableValue('--trbewc-secondary-bg-color'),
    primaryTextColor: getCSSVariableValue('--trbewc-primary-text-color'),
    secondaryTextColor: getCSSVariableValue('--trbewc-secondary-text-color'),
    linkColor: getCSSVariableValue('--trbewc-link-color'),
    accentColor: getCSSVariableValue('--trbewc-accent-color'),
    primaryInputColor: getCSSVariableValue('--trbewc-primary-input-color'),
    primaryInputBorderColor: getCSSVariableValue('--trbewc-primary-input-border-color'),
    errorBGColor: getCSSVariableValue('--trbewc-error-bg-color'),
    iconHoverColor: getCSSVariableValue('--trbewc-icon-hover-color'),
  }
}
