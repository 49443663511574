import React, { useEffect } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import useThemeStyles from 'hooks/useThemeStyles';
import administrationConfigs from 'configs/userbarAdministration.json';
import { BarLink } from 'foundation/types';
import { Nav } from 'react-bootstrap';
import sprite from 'assets/svg/sprite.svg';
import Notification from 'components/Notification/Notification';
import { useNotification } from 'contexts/NotificationContext';

const UserBarAdministration: React.FC = () => {
  const styles = useThemeStyles();
  const { notification, hideNotification } = useNotification();
  const location = useLocation();

  useEffect(() => {
    hideNotification();
  }, [location.pathname, hideNotification]);

  return (
    <div className="trbewc-settings-page">
      {notification.visible && <Notification/>}
      <div className="trbewc-sidebar trbewc-sidebar--static">
        <Nav className="trbewc-sidebar__nav trbewc-sidebar__nav--static flex-column">
          {administrationConfigs.map((section, sectionIndex) => (
            <React.Fragment key={sectionIndex}>
              {section.map((item: BarLink, itemIndex: number) => (
                (
                  <NavLink
                    key={itemIndex}
                    className={({ isActive }) =>
                      isActive
                        ? 'trbewc-sidebar__nav__nav-link trbewc-sidebar__nav__nav-link--active trbewc-sidebar__nav__nav-link--static'
                        : 'trbewc-sidebar__nav__nav-link trbewc-sidebar__nav__nav-link--static'
                    }
                    to={item.link}
                  >
                    <div className="trbewc-sidebar__nav__nav-padding">
                      <svg style={styles.iconStyle} className="trbewc-sidebar__nav__nav-icon">
                        <use href={sprite + item.icon}></use>
                      </svg>
                      {item.text}
                    </div>
                  </NavLink>
                )
              ))}
              {sectionIndex < administrationConfigs.length - 1 && (
                <div style={styles.dividerStyle}></div>
              )}
            </React.Fragment>
          ))}
        </Nav>
      </div>
      <div className="trbewc-settings-content">
        <Outlet/>
      </div>
    </div>
  );
};

export default UserBarAdministration;
