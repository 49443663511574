export const applicationConstants = {
  baseServerURL: process.env.REACT_APP_SERVER_URL + '' + process.env.REACT_APP_SERVER_CONTEXT_PATH,
  serverFrameworkURL: process.env.REACT_APP_SERVER_FRAMEWORK_BASE_PREFIX,
    CSRF_CONFIGS: {
      HEADER_NAME: 't-csrf-header',
      TOKEN_KEY: 't-csrf-token'
    },
    WEBSOCKETS_CONFIGS: {
      DESTINATIONS: {
        ACCOUNTS: '/user/queue/accounts'
      },
      ENDPOINT: '/exchangeshub-server-websockets',
      RECONNECT_DELAY: 3000,
      HEARTBEAT_INCOMING: 10000,
      HEARTBEAT_OUTGOING: 10000
    },
    ONE_HOUR_IN_MILLISECONDS: 60 * 60 * 1000,
    isNavBarThemeSwitcherVisible: () => {
        return process.env.REACT_APP_NAVBAR_THEME_SWITCHER_VISIBLE === 'true'
    },
    isNavbarWidgetVisible: () => {
      return process.env.REACT_APP_NAVBAR_WIDGETS_HARDWARE_VISIBLE === 'true'
    }
}
