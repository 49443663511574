import { NotificationContext } from 'contexts/NotificationContext';
import { useContext, useEffect } from 'react';

let showError: (message: string) => void = () => {};

export const useInitializeNotificationHelper = () => {
  const context = useContext(NotificationContext);

  useEffect(() => {
    if (context) {
      showError = context.showError;
    }
  }, [context]);
};

export { showError };
