import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBarAuthentication: React.FC = () => {
  const getClassName = ({ isActive }: { isActive: boolean }) => 'trbewc-nav-bar-auth-link' + (isActive ? ' active' : '');

  return (
    <div>
      <NavLink className={getClassName} to='/login'>
        Sign in
      </NavLink>
      <NavLink className={getClassName} to='/signup'>
        Sign up
      </NavLink>
    </div>
  );
};

export default NavBarAuthentication;
