import userbarConfigs from 'configs/userbar.json';
import userbarSettingsConfigs from 'configs/userbarSettings.json';
import sidebarConfigs from 'configs/sidebar.json';
import { BarLink } from 'foundation/types';

const userbar: BarLink[][] = userbarConfigs;
const userbarSettings: BarLink[][] = userbarSettingsConfigs;
const sidebar: BarLink[][] = sidebarConfigs;

const allConfigs: BarLink[] = [...userbar, ...userbarSettings, ...sidebar].flat();
export const getRequiredAuthoritiesForPath = (path: string): string[] => {
  const configItem = allConfigs.find(item => item.link === path);
  return configItem && configItem.authorities ? configItem.authorities : [];
};
