import React, { useState, useEffect } from 'react';
import useThemeStyles from 'hooks/useThemeStyles';
import Form from 'react-bootstrap/Form';
import sprite from 'assets/svg/sprite.svg';
import 'assets/css/components/search.css';
import useSearch from 'hooks/useSearch';
import { SearchProps } from './types';

const Search: React.FC<SearchProps> = ({
                                         searchClassStyle = '',
                                         placeholder = 'Search',
                                         onSearch,
                                         onReset,
                                         values,
                                         searchableFields,
                                         displayFields
                                        }) => {
  const styles = useThemeStyles();
  const { results, search } = useSearch(values, searchableFields, displayFields);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (query.length > 0) {
      onSearch(results, query);
    } else {
      onReset();
    }
  }, [results, query, onSearch, onReset]);

  const handleSearch = (value: string) => {
    setQuery(value);
    search(value);
  };

  return (
    <Form className="trbewc-search">
      <input
        type="search"
        className={`trbewc-search__nav-search ${searchClassStyle}`}
        placeholder={placeholder}
        style={styles.inputSearchStyle}
        onChange={event => handleSearch(event.target.value)}
        value={query}
      />
      <svg style={styles.iconStyle} className="trbewc-search__search-icon">
        <use href={sprite + '#icon-search'}></use>
      </svg>
    </Form>
  );
};

export default Search;
