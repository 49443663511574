import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { applicationConstants } from 'constants/application.constants';
import { saveRefreshTokenFailure } from 'services/localStorageService';
import { showError } from 'utils/notification.utility';

const axiosClient = axios.create({
  baseURL: applicationConstants.baseServerURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

let navigateFunction: NavigateFunction | null = null;

export const setNavigateFunction = (navigate: NavigateFunction) => {
  navigateFunction = navigate;
};

const isLoginPage = () => {
  const currentPath = window.location.pathname;
  return currentPath === '/login';
};

const isSignUpPage = () => {
  const currentPath = window.location.pathname;
  return currentPath === '/signup';
};

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isLoginPage() || isSignUpPage()) {
        return Promise.reject(error);
      }

      if (originalRequest.url.includes('/refreshToken')) {
        saveRefreshTokenFailure();
        window.location.href = '/login';
        return new Promise(() => {});
      }

      try {
        await axiosClient.post(`${applicationConstants.serverFrameworkURL}/authentication/refreshToken`);
        return axiosClient(originalRequest);
      } catch (err) {
        saveRefreshTokenFailure();
        if (!isLoginPage() || !isSignUpPage()) {
          window.location.href = '/login';
        }
        return new Promise(() => {});
      }
    }

    if (error.response && error.response.status !== 401) {
      showError(`${error.response.data.message || 'Something went wrong'}`);
    }
    return Promise.reject(error)
  }
);

export default axiosClient;

