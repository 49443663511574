import React from 'react';
import { ErrorMessage } from 'formik';
import sprite from 'assets/svg/sprite.svg';
import { InputErrorMessageProps } from 'components/Errors/types';

const InputErrorMessage: React.FC<InputErrorMessageProps> = ({ name }) => {
  return (
    <ErrorMessage name={name}>
      {(msg) => (
        <div className="trbewc-input-error-message">
          <svg className="trbewc-input-error-message__icon-warning">
            <use href={sprite + '#icon-warning'}/>
          </svg>
          {msg}
        </div>
      )}
    </ErrorMessage>
  );
};

export default InputErrorMessage;
