import React from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import NotFoundPage from 'pages/NotFoundPage';
import LoginPage from 'pages/Authentication/LoginPage';
import SignupPage from 'pages/Authentication/SignupPage';
import AuthenticatedIndexPage from 'pages/AuthenticatedIndexPage';
import UserBarSettings from 'components/UserBar/UserBarSettings';
import ProfilePage from 'pages/UserBar/Settings/ProfilePage';
import AccountPage from 'pages/UserBar/Settings/AccountPage';
import SecurityPage from 'pages/UserBar/Settings/SecurityPage';
import SessionsPage from 'pages/UserBar/Settings/SessionsPage';
import InvitationsPage from 'pages/UserBar/Settings/InvitationsPage';
import Table1 from 'pages/SideBar/Tables/Table1';
import Table2 from 'pages/SideBar/Tables/Table2';
import { AuthenticatedRoute, AnonymousRoute } from 'components/IndexRoute/IndexRoute';
import InvitationNewPage from 'components/InvitationNewPage/InvitationNewPage';
import ForbiddenPage from '../pages/ForbiddenPage';
import UserBarAdministration from 'components/UserBar/UserBarAdministration';
import AdministrationSessionsPage from 'pages/UserBar/Administration/AdministrationSessionsPage';
import AdministrationInvitesPage from 'pages/UserBar/Administration/AdministrationInvitesPage';
import AdministrationServerPage from 'pages/UserBar/Administration/AdministrationServerPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthenticatedRoute>
        <Outlet/>
      </AuthenticatedRoute>
    ),
    children: [
      {
        index: true,
        element: <AuthenticatedIndexPage/>
      },
      {
        path: 'settings',
        element: <UserBarSettings/>,
        children: [
          {
            index: true,
            element: <Navigate to="profile" replace/>
          },
          {
            path: 'profile',
            element: <ProfilePage/>
          },
          {
            path: 'account',
            element: <AccountPage/>
          },
          {
            path: 'security',
            element: <SecurityPage/>
          },
          {
            path: 'sessions',
            element: <SessionsPage/>
          },
          {
            path: 'invitations',
            element: <Outlet/>,
            children: [
              {
                index: true,
                element: <InvitationsPage/>
              },
              {
                path: 'new',
                element: (
                  <AuthenticatedRoute requiredAuthorities={['invitationCode:write']} noLayout>
                    <InvitationNewPage/>
                  </AuthenticatedRoute>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'administration',
        element:
          <AuthenticatedRoute requiredAuthorities={['superadmin']} noLayout>
            <UserBarAdministration/>
          </AuthenticatedRoute>,
        children: [
          {
            index: true,
            element: <Navigate to="sessions" replace/>
          },
          {
            path: 'sessions',
            element: <AdministrationSessionsPage/>
          },
          {
            path: 'invites',
            element: <AdministrationInvitesPage/>
          },
          {
            path: 'server',
            element: <AdministrationServerPage/>
          },
        ]
      },
      {
        path: 'tables/table1',
        element: <Table1/>
      },
      {
        path: 'tables/table2',
        element: <Table2/>
      }
    ]
  },
  {
    path: '/login',
    element: <AnonymousRoute/>,
    children: [
      {
        index: true,
        element: <LoginPage/>
      }
    ]
  },
  {
    path: '/signup',
    element: <AnonymousRoute/>,
    children: [
      {
        index: true,
        element: <SignupPage/>
      }
    ]
  },
  {
    path: '/403',
    element: <ForbiddenPage/>
  },
  {
    path: '*',
    element: <NotFoundPage/>
  }
]);
