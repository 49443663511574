import React, { ReactElement } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import AnonymousLayout from 'components/Layouts/AnonymousLayout/AnonymousLayout';
import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout/AuthenticatedLayout';
import { getCurrentClientUser, getRefreshTokenFailure } from 'services/localStorageService';
import { AuthenticatedRouteProps } from 'components/IndexRoute/types';
import { getRequiredAuthoritiesForPath } from 'utils/authority.utility';

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
                                                                 requiredAuthorities = [],
                                                                 children,
                                                                 noLayout
                                                                }): ReactElement | null => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const userAuthorities = getCurrentClientUser()?.authorities || [];
  const pathAuthorities = getRequiredAuthoritiesForPath(location.pathname);
  const currentRequiredAuthorities = requiredAuthorities.length > 0 ? requiredAuthorities : pathAuthorities;
  const hasRequiredAuthority = currentRequiredAuthorities.length === 0 || currentRequiredAuthorities.every(auth => userAuthorities.includes(auth));

  if (!isAuthenticated) {
    return <Navigate to="/login" replace/>;
  }

  if (!hasRequiredAuthority) {
    return <Navigate to="/403" replace/>;
  }

  const content = children || <Outlet/>;
  return noLayout ? <>{content}</> : <AuthenticatedLayout>{content}</AuthenticatedLayout>;
};

const AnonymousRoute: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const refreshTokenFailure = getRefreshTokenFailure() === 'true';

  return !isAuthenticated || refreshTokenFailure ? (
    <AnonymousLayout>
      <Outlet/>
    </AnonymousLayout>
  ) : (
    <Navigate to="/" replace/>
  );
};

export { AuthenticatedRoute, AnonymousRoute };
