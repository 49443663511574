import React, { useState } from 'react';
import { Formik, Field, Form as FormikForm } from 'formik';
import { validationSchemaChangePassword } from 'validation/validationSchemas';
import useThemeStyles from 'hooks/useThemeStyles';
import { useAuth } from 'contexts/AuthContext';
import Form from 'react-bootstrap/Form';
import 'assets/css/pages/userbar-security-page.css';
import 'assets/css/components/forms.css';
import InputErrorMessage from 'components/Errors/InputErrorMessage';
import { useNotification } from 'contexts/NotificationContext';
import { useHandleError } from 'hooks/useHandleError';
import sprite from 'assets/svg/sprite.svg';

const SecurityPage: React.FC = () => {
  const styles = useThemeStyles();
  const { showSuccess, showError } = useNotification();
  const { executeChangePasswordRequest } = useAuth();
  const handleError = useHandleError();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  };

  const handleSubmit = async (values: {
    newPassword: string;
    confirmPassword: string
  }, setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      await executeChangePasswordRequest(values.newPassword, values.confirmPassword);
      showSuccess('Password successfully changed');
    } catch (error: any) {
      handleError(error);
    }
    setSubmitting(false);
  };

  return (
    <div className='trbewc-security-page'>
      <Formik
        initialValues={{ newPassword: '', confirmPassword: '' }}
        validationSchema={validationSchemaChangePassword}
        onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
      >
        {({ isSubmitting, handleChange, values }) => (
          <FormikForm>
            <h4 className='trbewc-userbar-settings__title'>Change password</h4>
            <div style={styles.dividerStyle}></div>
            <Form.Group className="trbewc-userbar-settings__form-group mb-3 position-relative">
              <Form.Label htmlFor="newPassword" style={styles.labelStyle}>New password</Form.Label>
              <div className='position-relative'>
                <Field
                  id="newPassword"
                  name="newPassword"
                  type={showPassword ? 'text' : 'password'}
                  aria-describedby="passwordHelpBlock"
                  className="trbewc-security-page__input form-control"
                  onChange={handleChange}
                  value={values.newPassword}
                  autoComplete="new-password"
                />
                <svg className="trbewc-form__form-icon" onClick={togglePasswordVisibility} width='15'
                     height='15'>
                  <use href={sprite + (showPassword ? '#icon-eye' : '#icon-eye-blocked')}></use>
                </svg>
              </div>
              <InputErrorMessage name="newPassword"/>
            </Form.Group>
            <Form.Group className="trbewc-userbar-settings__form-group mb-3 position-relative">
              <Form.Label htmlFor="confirmPassword" style={styles.labelStyle}>Confirm new
                password</Form.Label>
              <div className='position-relative'>
                <Field
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  aria-describedby="passwordHelpBlock"
                  className="trbewc-security-page__input form-control"
                  onChange={handleChange}
                  value={values.confirmPassword}
                  autoComplete="new-password"
                />
                <svg className="trbewc-form__form-icon" onClick={toggleConfirmPasswordVisibility} width='15'
                     height='15'>
                  <use href={sprite + (showConfirmPassword ? '#icon-eye' : '#icon-eye-blocked')}></use>
                </svg>
              </div>
              <InputErrorMessage name="confirmPassword"/>
            </Form.Group>
            <button className='trbewc-page__button' type="submit" disabled={isSubmitting}>
              Save
            </button>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default SecurityPage;
