import { useState, useEffect, useMemo } from 'react';
import MiniSearch from 'minisearch';

interface FilterOption {
  icon: string;
  label: string;
  action?: (values: any[]) => any[];
}

const useTableFilter = (initialValues: any[], fields: string[], filterOptions?: FilterOption[]) => {
  const [originalValues, setOriginalValues] = useState<any[]>(initialValues);
  const [filteredValues, setFilteredValues] = useState<any[]>(initialValues);
  const [activeFilter, setActiveFilter] = useState<string>('');

  const miniSearch = useMemo(() => {
    const search = new MiniSearch({
      fields,
      storeFields: fields,
    });
    search.addAll(initialValues);
    return search;
  }, [initialValues, fields]);

  useEffect(() => {
    setOriginalValues(initialValues);
    setFilteredValues(initialValues);
  }, [initialValues]);

  const handleFilter = (filterValue: string) => {
    if (filterValue === activeFilter) {
      setActiveFilter('');
      setFilteredValues(originalValues);
    } else {
      setActiveFilter(filterValue);

      let filtered: any[] = [];

      switch (filterValue) {
        case 'Online':
          filtered = originalValues.filter(value => value.current === true);
          break;
        case 'Offline':
          filtered = originalValues.filter(value => value.current === false);
          break;
        default:
          if (fields.includes('authorities')) {
            filtered = originalValues.filter(value => value.authorities === filterValue);
          } else {
            filtered = miniSearch.search(filterValue).map(result => {
              return initialValues.find(value => value.id === result.id);
            }).filter(Boolean);
          }
          break;
      }

      setFilteredValues(filtered);
    }
  };

  return {
    filteredValues,
    handleFilter,
    activeFilter
  };
};

export default useTableFilter;
