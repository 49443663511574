import React from 'react';
import { NavLink } from 'react-router-dom';

const ForbiddenPage: React.FC = () => {
  return (
    <div className="trbewc-not-found-container align-content-center text-center">
      <h2 className=" display-3">403</h2>
      <p>You do not have permission to access this page</p>
      <NavLink className="trbewc-not-found-container__link" to='/'>Go to Home</NavLink>
    </div>
  )
    ;
};

export default ForbiddenPage;
