import axiosClient from 'axiosClient/axiosClient';
import { applicationConstants } from 'constants/application.constants';
import { getErrorMessage } from 'utils/errors.utility';
import {
  RequestUserLogin,
  RequestUserChangePasswordBasic,
  RequestUserUpdate1,
  ResponseInvitationCodes,
  ResponseInvitationCode,
  ResponseAuthorities,
  RequestUserRegistration1
} from './types';

// ================================================================================================================
// Authentication
// ================================================================================================================

export const signup = async (payload: RequestUserRegistration1) => {
  try {
    const response = await axiosClient.post(`${applicationConstants.serverFrameworkURL}/registration/register1`, payload);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const login = async (payload: RequestUserLogin) => {
  try {
    const response = await axiosClient.post(`${applicationConstants.serverFrameworkURL}/authentication/login`, payload);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const logout = async () => {
  try {
    await axiosClient.post(`${applicationConstants.serverFrameworkURL}/authentication/logout`);
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const updatePassword = async (payload: RequestUserChangePasswordBasic) => {
  try {
    const response = await axiosClient.post(`${applicationConstants.serverFrameworkURL}/users/changePassword1`, payload);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const updateProfile = async (payload: RequestUserUpdate1) => {
  try {
    const response = await axiosClient.post(`${applicationConstants.serverFrameworkURL}/users/update1`, payload);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// ================================================================================================================
// Invitations
// ================================================================================================================

export const getInvitations = async (): Promise<ResponseInvitationCodes> => {
  try {
    const response = await axiosClient.get<ResponseInvitationCodes>(`${applicationConstants.serverFrameworkURL}/invitationCodes`);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const addInvitation = async (payload: ResponseAuthorities): Promise<ResponseInvitationCode> => {
  try {
    return await axiosClient.post(`${applicationConstants.serverFrameworkURL}/invitationCodes`, payload);
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// ================================================================================================================
// Sessions
// ================================================================================================================

export const renewSession = async (sessionId: string) => {
  try {
    const response = await axiosClient.post(`${applicationConstants.serverFrameworkURL}/sessions/${sessionId}/renew/manually`);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const deleteSession = async (sessionId: string) => {
  try {
    await axiosClient.delete(`${applicationConstants.serverFrameworkURL}/sessions/${sessionId}`);
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// ================================================================================================================
// Users Sessions
// ================================================================================================================

export const getSessions = async () => {
  try {
    return await axiosClient.get(`${applicationConstants.serverFrameworkURL}/sessions`);
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const getCurrentSession = async () => {
  try {
    return await axiosClient.get(`${applicationConstants.serverFrameworkURL}/sessions/current`);
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// ================================================================================================================
// Superadmin Sessions
// ================================================================================================================

export const getSuperadminSessions = async () => {
  try {
    return await axiosClient.get(`${applicationConstants.serverFrameworkURL}/superadmin/sessions`);
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const renewSuperadminSession = async (sessionId: string) => {
  try {
    const response = await axiosClient.post(`${applicationConstants.serverFrameworkURL}/superadmin/sessions/${sessionId}/renew/manually`);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};
// ================================================================================================================
// Superadmin Reset Server
// ================================================================================================================
export const resetServer = async () => {
  try {
    const response = await axiosClient.post(`${applicationConstants.serverFrameworkURL}/superadmin/server/reset`);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const deleteSuperadminSession = async (sessionId: string) => {
  try {
    await axiosClient.delete(`${applicationConstants.serverFrameworkURL}/superadmin/sessions/${sessionId}`);
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const deleteAllSuperadminSessions = async () => {
  try {
    await axiosClient.delete(`${applicationConstants.serverFrameworkURL}/superadmin/sessions`);
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const getUnusedInvitationCodes = async () => {
  try {
    return await axiosClient.get(`${applicationConstants.serverFrameworkURL}/superadmin/invitationCodes/unused`);
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// ================================================================================================================
// Test Data
// ================================================================================================================

export const getTestDataSessions = async () => {
  try {
    return await axiosClient.get(`${applicationConstants.serverFrameworkURL}/test-data/sessions`);
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};
