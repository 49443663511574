import React, { useEffect, useState } from 'react';
import tableConfigs from './table2-configs.json';
import Table from 'components/Table/Table';
import { Container } from 'react-bootstrap';
import { getTestDataSessions } from 'api/framework';
import useTableFilter from 'hooks/useTableFilter';
import { useLocation } from 'react-router-dom';
import TableControlPanel from 'components/TableControlPanel/TableControlPanel';
import { useNotification } from 'contexts/NotificationContext';
import Notification from 'components/Notification/Notification';

const Table2Page: React.FC = () => {
  const { notification, hideNotification } = useNotification();
  const location = useLocation();

  const [tableValues, setTableValues] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getTestDataSessions();
      setTableValues(response.data.sessions);
      const countries = response.data.countries.map((country: string) => ({
        id: country,
        label: country
      }));
      setCountries(countries);
    };
    fetchData();
  }, []);

  useEffect(() => {
    hideNotification();
  }, [location.pathname, hideNotification]);

  const handleSearch = (results: any[], query: string) => {
    if (query.length === 0) {
      setSearchPerformed(false);
      setSearchResults([]);
    } else {
      setSearchResults(results);
      setSearchPerformed(true);
    }
  };

  const { filteredValues, handleFilter } = useTableFilter(tableValues, ['country']);

  const searchableFields = tableConfigs.headers
    .filter(header => header.searchable)
    .map(header => header.fieldName);

  const displayFields = tableConfigs.headers.map(header => header.fieldName);

  const displayValues = searchPerformed ? searchResults : filteredValues;

  const searchConfigs = {
    onSearch: handleSearch,
    onReset: () => setSearchPerformed(false),
    values: tableValues,
    searchableFields,
    displayFields
  };

  const dropdownConfigs = [
    {
      label: 'Country:',
      options: countries,
      title: 'Select type',
      menuStyle: 'trbewc-dropdown__menu-type',
      onFilter: handleFilter
    },
    {
      label: 'Visibility:',
      options: tableConfigs.dropdownVisibilityOptions,
      title: 'Select visibility',
    },
    {
      label: 'Sort by:',
      options: tableConfigs.dropdownSortByOptions,
      title: 'Select sort view',
      menuStyle: 'trbewc-dropdown__menu-sortby',
    }
  ];

  return (
    <>
      {notification.visible && <Notification/>}
      <div className='trbewc-table-page trbewc-table-page-container'>
        <Container>
          <TableControlPanel
            isSearchVisible={true}
            isDropdownVisible={true}
            searchConfigs={searchConfigs}
            dropdownConfigs={dropdownConfigs}
          />
          <div className='trbewc-table-page__body-container'>
            <Table
              headers={tableConfigs.headers}
              values={displayValues}
              filterOptions={[]}
              sortOptions={tableConfigs.sortOptions}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Table2Page;
