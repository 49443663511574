import React from 'react';
import 'assets/css/pages/sidebar-authenticated-page.css';
import { useAuth } from 'contexts/AuthContext';

const AuthenticatedIndexPage: React.FC = () => {
  const { user } = useAuth();

  return (
    <div className='trbewc-user-page'>
      <h1 className='trbewc-user-page__user-page-title'>Welcome, {user?.username}</h1>
      <p className='trbewc-user-page__user-page-describtion'>Start your journey</p>
    </div>
  );
};

export default AuthenticatedIndexPage;
