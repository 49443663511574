import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AnonymousLayoutProps } from './types';
import useThemeStyles from 'hooks/useThemeStyles';
import NavBar from 'components/NavBar/NavBar';

const AnonymousLayout: React.FC<AnonymousLayoutProps> = ({ children }) => {
  const styles = useThemeStyles();
  return (
    <div style={styles.container}>
      <NavBar/>
      <Container fluid>
        <Row className="w-100">
          <Col md={ { span: 4, offset: 4 } }>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AnonymousLayout;
