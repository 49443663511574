import React, { useEffect, useState } from 'react';
import { applicationConstants } from 'constants/application.constants';
import useThemeStyles from 'hooks/useThemeStyles';
import ThemeSwitcher from 'components/ThemeSwitcher/ThemeSwitcher';
import UserBar from 'components/UserBar/UserBar';
import SideBar from 'components/SideBar/SideBar';
import Logo from 'components/Logo/Logo';
import { Container } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { useAuth } from 'contexts/AuthContext';
import NavBarAuthentication from 'components/NavBar/NavBarAuthentication';
import Widget from 'components/Widget/Widget';
import { getCurrentSession } from 'api/framework';

const NavBar: React.FC = () => {
  const styles = useThemeStyles();
  const { isAuthenticated } = useAuth();

  const [hardware, setHardware] = useState([]);
  const [version, setVersion] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      const fetchData = async () => {
        const response = await getCurrentSession();
        setHardware(response.data.attributes.hardware.datapoint.rows);
        setVersion(response.data.attributes.hardware.version);
      };
      fetchData();
    }
  }, [isAuthenticated]);

  return (
    <Navbar style={styles.navbarStyle}>
      <Container className='d-flex justify-content-start p-0'>
        {isAuthenticated && <SideBar/>}
        <Logo/>
      </Container>
      <Container className='d-flex justify-content-end p-0 g-16'>
        {isAuthenticated && applicationConstants.isNavbarWidgetVisible() && (
          <Widget data={hardware} version={version}/>
        )}
        {applicationConstants.isNavBarThemeSwitcherVisible() && <ThemeSwitcher/>}
        {!isAuthenticated && <NavBarAuthentication/>}
        {isAuthenticated && <UserBar/>}
      </Container>
    </Navbar>
  );
};

export default NavBar;
