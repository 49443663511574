import React, { useEffect, useState } from 'react';
import useThemeStyles from 'hooks/useThemeStyles';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/components/forms.css';
import { validationSchemaSignup } from 'validation/validationSchemas';
import { Formik, Field, Form as FormikForm } from 'formik';
import Form from 'react-bootstrap/Form';
import sprite from 'assets/svg/sprite.svg';
import { SignupFormProps } from './types';
import { useAuth } from 'contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import InputErrorMessage from 'components/Errors/InputErrorMessage';
import { signup } from 'api/framework';
import { getErrorMessage } from 'utils/errors.utility';

const SignupPage: React.FC = () => {
  const styles = useThemeStyles();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (values: SignupFormProps, setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      await signup(values);
      navigate('/login');
    } catch (error: any) {
      setError(getErrorMessage(error));
    }
  };

  const handleCloseError = () => {
    setError('');
  };

  return (
    <div className='trbewc-form' style={styles.content}>
      {error && (
        <div className="trbewc-form-notification">
          {error}
          <button
            style={styles.buttonCloseStyle}
            className="trbewc-button trbewc-button-close--error"
            onClick={handleCloseError}
          >
            <svg className="trbewc-button__icon-close--error" width={13} height={13}>
              <use href={sprite + '#icon-close'}></use>
            </svg>
          </button>
        </div>
      )}
      <div className='trbewc-form__form-container' style={styles.formContainer}>
        <Formik
          initialValues={{
            username: '',
            password: '',
            confirmPassword: '',
            invitationCode: '',
            zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
          }}
          validationSchema={validationSchemaSignup}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        >
          {({ isSubmitting, handleChange, handleBlur, values }) => (
            <FormikForm>
              <Form.Group className="mb-3 position-relative" controlId="formBasicUsername">
                <Form.Label style={styles.labelStyle}>Username</Form.Label>
                <Field
                  className="trbewc-form__form-control form-control"
                  type="text"
                  name="username"
                  style={styles.inputStyle}
                  onChange={handleChange}
                  value={values.username}
                />
                <InputErrorMessage name="username"/>
              </Form.Group>
              <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                <Form.Label style={styles.labelStyle}>Password</Form.Label>
                <div className='trbewc-form__form-input'>
                  <Field
                    className="trbewc-form__form-control form-control"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    style={{ ...styles.inputStyle }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <svg className="trbewc-form__form-icon" onClick={togglePasswordVisibility} width='15' height='15'>
                    <use
                      href={sprite + (showPassword ? '#icon-eye' : '#icon-eye-blocked')}
                    ></use>
                  </svg>
                </div>
                <InputErrorMessage name="password"/>
              </Form.Group>
              <Form.Group className="mb-3 position-relative" controlId="formBasicConfirmPassword">
                <Form.Label style={styles.labelStyle}>Confirm Password</Form.Label>
                <div className='trbewc-form__form-input'>
                  <Field
                    className="trbewc-form__form-control form-control"
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    style={{ ...styles.inputStyle }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                  />
                  <svg className="trbewc-form__form-icon" onClick={toggleConfirmPasswordVisibility} width='15'
                       height='15'>
                    <use
                      href={sprite + (showConfirmPassword ? '#icon-eye' : '#icon-eye-blocked')}
                    ></use>
                  </svg>
                </div>
                <InputErrorMessage name="confirmPassword"/>
              </Form.Group>
              <Form.Group className="mb-3 position-relative" controlId="formBasicInvitationCode">
                <Form.Label style={styles.labelStyle}>Invitation</Form.Label>
                <Field
                  className="trbewc-form__form-control form-control"
                  type="text"
                  name="invitationCode"
                  style={styles.inputStyle}
                  onChange={handleChange}
                  value={values.invitationCode}
                />
                <InputErrorMessage name="invitationCode"/>
              </Form.Group>
              <button className="trbewc-form__button" type="submit" disabled={isSubmitting}>
                Sign up
              </button>
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignupPage;
