import React, { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import useThemeStyles from 'hooks/useThemeStyles';
import packageJSON from '../../../package.json';
import DatesUtility from 'utils/dates.utility';
import userbarConfigs from 'configs/userbar.json';
import { Offcanvas, Nav } from 'react-bootstrap';
import sprite from 'assets/svg/sprite.svg';
import { BarLink } from 'foundation/types';

const UserBar: React.FC = () => {
  const { user, executeLogoutRequest } = useAuth();
  const navigate = useNavigate();
  const styles = useThemeStyles();

  const [error, setError] = useState<string>('');
  const [show, setShow] = useState(false);

  const toggleUserbar = () => setShow(prevShow => !prevShow);

  const handleLogout = async () => {
    try {
      await executeLogoutRequest();
      navigate('/login');
    } catch (error) {
      setError('Logout failed. Please try again');
    }
  };

  const userAuthorities = user?.authorities || [];

  const hasRequiredAuthority = (item: BarLink): boolean => {
    return !item.authorities || item.authorities.length === 0 || item.authorities.some(auth => userAuthorities.includes(auth));
  };

  return (
    <div className="trbewc-sidebar">
      <>
        <button style={styles.buttonNavbarStyle} className="trbewc-sidebar__button" onClick={toggleUserbar}>
          Hello, {user?.username}
        </button>
        <Offcanvas style={styles.sidebarStyle} className="trbewc-sidebar--right-border" show={show}
                   onHide={toggleUserbar} placement="end">
          <Offcanvas.Header className="trbewc-sidebar__header trbewc-sidebar__header--right">
            <button style={styles.buttonCloseStyle} onClick={toggleUserbar}
                    className="trbewc-button trbewc-button-close">
              <svg className="trbewc-button__icon-close" width={13} height={13}>
                <use href={sprite + '#icon-close'}></use>
              </svg>
            </button>
          </Offcanvas.Header>
          <Offcanvas.Body className="trbewc-sidebar__body">
            <Nav className="trbewc-sidebar__nav flex-column">
              {userbarConfigs.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                  {section.map((item: BarLink, itemIndex) => (
                    hasRequiredAuthority(item) && (
                      <NavLink
                        key={itemIndex}
                        className="trbewc-sidebar__nav__nav-link"
                        to={item.link}
                        onClick={toggleUserbar}
                      >
                        <div className="trbewc-sidebar__nav__nav-padding">
                          <svg
                            style={styles.iconStyle}
                            className="trbewc-sidebar__nav__nav-icon"
                          >
                            <use href={sprite + item.icon}></use>
                          </svg>
                          {item.text}
                        </div>
                      </NavLink>
                    )
                  ))}
                  {sectionIndex < userbarConfigs.length - 1 && (
                    <div style={styles.dividerStyle}></div>
                  )}
                </React.Fragment>
              ))}
              <div style={styles.dividerStyle}></div>
              <Nav.Item className="trbewc-sidebar__nav__nav-link" onClick={handleLogout}>
                <div className="trbewc-sidebar__nav__nav-padding">
                  Sign out
                </div>
              </Nav.Item>
              <div style={styles.dividerStyle}></div>
              <span
                className="trbewc-app-version">© {DatesUtility.getCurrentYear()} ExchangesHub | v{packageJSON.version}</span>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
        {error && <div className="trbewc-sidebar__error-message">{error}</div>}
      </>
    </div>
  );
};

export default UserBar;
