import React, { useEffect, useState } from 'react';
import Table from 'components/Table/Table';
import { Container } from 'react-bootstrap';
import tableConfigs from './table1-configs.json';
import { getSessions } from 'api/framework';
import useTableFilter from 'hooks/useTableFilter';
import { useLocation } from 'react-router-dom';
import TableControlPanel from 'components/TableControlPanel/TableControlPanel';
import { useNotification } from 'contexts/NotificationContext';
import Notification from 'components/Notification/Notification';

const Table1: React.FC = () => {
  const { notification, hideNotification } = useNotification();
  const location = useLocation();

  const [tableValues, setTableValues] = useState<any[]>([]);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getSessions();
      setTableValues(response.data.sessions);
    };
    fetchData();
  }, []);

  useEffect(() => {
    hideNotification();
  }, [location.pathname, hideNotification]);

  const { filteredValues, handleFilter, activeFilter } = useTableFilter(tableValues, ['what']);

  const handleSearch = (results: any[]) => {
    setSearchResults(results);
    setSearchPerformed(true);
  };

  const resetSearch = () => {
    setSearchPerformed(false);
  };

  const actionHandlers: { [key: string]: (row: any) => void } = {
    edit: (row: any) => {
      console.log('Edit action for row:', row);
    },
    delete: (row: any) => {
      console.log('Delete action for row:', row);
    }
  };

  const actions = tableConfigs.actions.map(action => ({
    label: action.label,
    handler: actionHandlers[action.type],
    icon: action.icon
  }));

  const searchableFields = tableConfigs.headers
    .filter(header => header.searchable)
    .map(header => header.fieldName);

  const displayFields = tableConfigs.headers.map(header => header.fieldName);

  const displayValues = searchPerformed ? searchResults : filteredValues;

  const searchConfigs = {
    onSearch: handleSearch,
    onReset: () => resetSearch(),
    values: tableValues,
    searchableFields,
    displayFields
  };

  const filterConfigs = {
    filterButtons: tableConfigs.filterButtons,
    onFilter: handleFilter,
    isActive: activeFilter,
  };
  return (
    <>
      {notification.visible && <Notification/>}
      <div className='trbewc-table-page trbewc-table-page-container'>
        <Container>
          <TableControlPanel searchConfigs={searchConfigs} isSearchVisible={true} isFilterButtonsVisible={true}
                             filterConfigs={filterConfigs}/>
          <div className='trbewc-table-page__body-container'>
            <Table
              headers={tableConfigs.headers}
              values={displayValues}
              filterOptions={tableConfigs.filterOptions}
              sortOptions={tableConfigs.sortOptions}
              actions={actions}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Table1;
