import { CurrentClientUser } from 'api/types';

type ThemeType = 'light-theme' | 'dark-theme';

export const getTheme = (): ThemeType | null => {
  return localStorage.getItem('theme') as ThemeType | null;
};

export const saveTheme = (newTheme: ThemeType) => {
  localStorage.setItem('theme', newTheme);
};

export const saveUsername = (value: string): void => {
  localStorage.setItem('username', value);
};

export const getUsername = () => {
  return localStorage.getItem('username');
};

export const removeUsername = (): void => {
  localStorage.removeItem('username');
};

export const saveSplashScreen = (): void => {
  localStorage.setItem('splashScreenShowed', 'true');
};

export const getSplashScreen = (): string | null => {
  return localStorage.getItem('splashScreenShowed');
};

export const removeSplashScreen = () => {
  localStorage.removeItem('splashScreenShowed');
};

export const saveCurrentClientUser = (value: CurrentClientUser): void => {
  localStorage.setItem('currentClientUser', JSON.stringify(value));
};

export const getCurrentClientUser = (): CurrentClientUser | null => {
  const currentClientUser = localStorage.getItem('currentClientUser');
  return currentClientUser ? JSON.parse(currentClientUser) : null;
};

export const removeCurrentClientUser = (): void => {
  localStorage.removeItem('currentClientUser');
};

export const saveRefreshTokenFailure = () => {
  localStorage.setItem('refreshTokenFailure', 'true');
};

export const removeRefreshTokenFailure = () => {
  localStorage.removeItem('refreshTokenFailure');
};

export const getRefreshTokenFailure = (): string | null => {
  return localStorage.getItem('refreshTokenFailure');
};
