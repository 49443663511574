import React from 'react';
import useThemeStyles from 'hooks/useThemeStyles';

const AccountPage: React.FC = () => {
  const styles = useThemeStyles();

  return (
    <div className='trbewc-table-page'>
      <h4 className='trbewc-userbar-settings__title'>Change username</h4>
      <div style={styles.dividerStyle}></div>
      <p className='trbewc-table-page__text'>Changing your username is disabled</p>
      <button className='trbewc-page__button' type="submit" disabled>
        Change username
      </button>
    </div>
  );
};

export default AccountPage;
