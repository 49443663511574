import * as Yup from 'yup';

export const validationSchemaLogin = Yup.object().shape({
  username: Yup.string()
    .required('Username is required'),
  password: Yup.string()
    .min(8, 'Password must be be between 8 and 20 symbols')
    .max(20, 'Password must be be between 8 and 20 symbols')
    .required('Password is required'),
});

export const validationSchemaSignup = Yup.object().shape({
  username: Yup.string()
    .required('Username is required'),
  password: Yup.string()
    .min(8, 'Password must be be between 8 and 20 symbols')
    .max(20, 'Password must be be between 8 and 20 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
  invitationCode: Yup.string()
    .required('Invitation is required')
});

export const validationSchemaChangePassword = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password must be between 8 and 20 symbols')
    .max(20, 'Password must be between 8 and 20 symbols')
    .matches(/[A-Z]/, 'Password must contain an uppercase letter')
    .matches(/[a-z]/, 'Password must contain a lowercase letter')
    .matches(/\d/, 'Password must contain a number')
    .required('New password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm password is required')
});

export const validationSchemaProfile = Yup.object({
  name: Yup.string()
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
});
