import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import useThemeStyles from 'hooks/useThemeStyles';
import Form from 'react-bootstrap/Form';
import { addInvitation } from 'api/framework';
import { Permissions } from 'components/InvitationNewPage/types';
import { useNotification } from 'contexts/NotificationContext';
import { useHandleError } from 'hooks/useHandleError';

const InvitationNewPage = () => {
  const styles = useThemeStyles();
  const { showSuccess, showError } = useNotification();
  const handleError = useHandleError();

  const initialValues = {
    invitationCodeRead: false,
    invitationCodeWrite: false
  }

  const handleSubmit = async (values: Permissions) => {
    const selectedPermissions = [];
    if (values.invitationCodeRead) {
      selectedPermissions.push('invitationCode:read');
    }
    if (values.invitationCodeWrite) {
      selectedPermissions.push('invitationCode:write');
    }

    try {
      await addInvitation({ authorities: selectedPermissions });
      showSuccess('Success');
    } catch (error: any) {
      handleError(error);
    }
  };

  const customHandleChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const { name, checked } = e.target;
    if (name === 'invitationCodeWrite' && checked) {
      setFieldValue('invitationCodeRead', true);
    }
    setFieldValue(name, checked);
  };

  return (
    <div className='trbewc-invitation-page'>
      <h4 className='trbewc-userbar-settings__title'>Invitation Code</h4>
      <div style={styles.dividerStyle}></div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue }) => {
          const isSaveDisabled = !values.invitationCodeRead && !values.invitationCodeWrite;
          return (
            <FormikForm className='p-2'>
              <Form.Group>
                <Form.Check
                  style={styles.labelStyle}
                  type="checkbox"
                  label="invitationCode:read"
                  name="invitationCodeRead"
                  onChange={(e) => customHandleChange(e, setFieldValue)}
                  checked={values.invitationCodeRead}
                  disabled={values.invitationCodeWrite}
                />
                <Form.Check
                  style={styles.labelStyle}
                  type="checkbox"
                  label="invitationCode:write"
                  name="invitationCodeWrite"
                  onChange={(e) => customHandleChange(e, setFieldValue)}
                  checked={values.invitationCodeWrite}
                />
              </Form.Group>
              <div className="d-flex mt-3 g-16">
                <button type="submit" className='trbewc-page__button' disabled={isSaveDisabled}>
                  Save
                </button>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
};

export default InvitationNewPage;
