import React, { useState, useEffect, useRef } from 'react';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import ProgressBar from 'react-bootstrap/ProgressBar';
import CustomPopover from 'components/CustomPopover/CustomPopover';
import { nanoid } from 'nanoid';
import widgetConfigs from 'configs/hardware-widget-configs.json';
import { useWebSocketContext } from 'contexts/WebSocketContext';
import { WidgetProps, Hardware } from 'components/Widget/types';
import useThemeStyles from 'hooks/useThemeStyles';

const Widget: React.FC<WidgetProps> = ({ data, version }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [websocketsData, setWebsocketsData] = useState<Hardware[]>([]);
  const progressBarRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { messages } = useWebSocketContext();
  const styles = useThemeStyles();

  const showPopover = () => {
    setIsPopoverOpen(true);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    if (messages && messages.length > 0) {
      const latestMessage = messages[messages.length - 1];
      if (latestMessage.attributes && latestMessage.attributes.datapoint) {
        setWebsocketsData(latestMessage.attributes.datapoint.rows as Hardware[]);
      }
    }
  }, [messages]);

  const getProgressBarProps = (usage: number) => {
    const config = widgetConfigs.find(config => usage >= config.min && usage < config.max);
    if (config) {
      return {
        now: usage,
        className: config.className
      };
    }
    return {
      now: usage,
      className: 'trbewc-widget--red'
    };
  };

  const setProgressBarRef = (el: HTMLDivElement | null, index: number) => {
    if (el) {
      progressBarRefs.current[index] = el.querySelector('.progress-bar');
    } else {
      progressBarRefs.current[index] = null;
    }
  };

  useEffect(() => {
    progressBarRefs.current.forEach((ref, index) => {
      if (ref) {
        const { className } = getProgressBarProps((websocketsData[index] || data[index])?.usage);
        ref.classList.add(className);
      }
    });
  }, [messages, websocketsData, data, isPopoverOpen, styles]);

  const displayData = websocketsData.length > 0 ? websocketsData : data;

  return (
    <div onMouseEnter={showPopover}>
      <span className="trbewc-widget__name">Hardware</span>
      <CustomPopover isOpen={isPopoverOpen} closePopover={closePopover} version={version}>
        <ul>
          {displayData.map((item, index) => {
            const { now } = getProgressBarProps(item.usage);
            return (
              <li className="trbewc-widget__list-item" key={nanoid()}>
                <div>
                  <span className="trbewc-widget__hardware-name">{item.hardwareName}</span>
                  <div className="trbewc-widget__info">
                    <div className="trbewc-widget__time">
                      <TimeAgo timestamp={item.timestamp}/>
                      <span>{item.value}</span>
                    </div>
                    <div className="trbewc-widget__progress-bar-container">
                      <div className="d-flex justify-content-between">
                        <span>Usage</span>
                        <span>{now}%</span>
                      </div>
                      <div ref={el => setProgressBarRef(el, index)}>
                        <ProgressBar now={now}/>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </CustomPopover>
    </div>
  );
};

export default Widget;
