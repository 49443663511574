import { CustomPopoverProps } from './types';

const CustomPopover = ({ children, isOpen, closePopover, version }: CustomPopoverProps) => {
  return isOpen ? (
    <div className="trbewc-widget-container" onMouseLeave={closePopover}>
      <h3 className="trbewc-widget__title">Hardware - {version}</h3>
      <div>{children}</div>
    </div>
  ) : null;
};

export default CustomPopover;
