import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import axios from 'axios';
import { login, updatePassword, updateProfile, logout } from 'api/framework';
import { AuthContextProps } from './types';
import { getErrorMessage } from 'utils/errors.utility';
import { RequestUserLogin, RequestUserChangePasswordBasic, RequestUserUpdate1, CurrentClientUser } from 'api/types';
import {
  removeSplashScreen,
  removeUsername,
  saveUsername,
  saveCurrentClientUser,
  getCurrentClientUser,
  removeCurrentClientUser, removeRefreshTokenFailure, getRefreshTokenFailure
 } from 'services/localStorageService';

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('username'));
  const [user, setUser] = useState<CurrentClientUser | null>(getCurrentClientUser);
  const executeLoginRequest = async (username: string, password: string) => {
    try {
      const payload: RequestUserLogin = { username, password };
      const response: CurrentClientUser = await login(payload);
      saveUsername(username);
      setUser(response);
      saveCurrentClientUser(response);
      setIsAuthenticated(true);
    } catch (error) {
      throw new Error(getErrorMessage(error));
    }
  };

  const executeLogoutRequest = async () => {
    try {
      await logout();
      removeUsername();
      setIsAuthenticated(false);
      removeSplashScreen();
      setUser(null);
      removeCurrentClientUser();
    } catch (error: any) {
      console.error('Logout error:', error);
      throw new Error(getErrorMessage(error));
    }
  };

  const executeChangePasswordRequest = async (newPassword: string, confirmPassword: string) => {
    try {
      const payload: RequestUserChangePasswordBasic = { newPassword, confirmPassword };
      await updatePassword(payload);
    } catch (error: any) {
      throw new Error(getErrorMessage(error));
    }
  };

  const executeUpdateProfileRequest = async (profileData: RequestUserUpdate1) => {
    try {
      await updateProfile(profileData);
      setUser(prev => {
        const updatedProfile = prev ? { ...prev, ...profileData } : null;
        if (updatedProfile) {
          saveCurrentClientUser(updatedProfile);
        }
        return updatedProfile;
      });
    } catch (error: any) {
      throw new Error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          setIsAuthenticated(false);
          removeUsername();
          removeCurrentClientUser();
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );

    const refreshTokenFailure = getRefreshTokenFailure();

    if (refreshTokenFailure === 'true') {
      removeRefreshTokenFailure();
      setIsAuthenticated(false);
      removeUsername();
      setUser(null);
      removeCurrentClientUser();
      window.location.href = '/login';
    }

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      executeLoginRequest,
      executeLogoutRequest,
      executeChangePasswordRequest,
      executeUpdateProfileRequest,
      user
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
