import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import useThemeStyles from 'hooks/useThemeStyles';
import sidebarConfig from 'configs/sidebar.json';
import Logo from 'components/Logo/Logo';
import { Offcanvas, Nav } from 'react-bootstrap';
import sprite from 'assets/svg/sprite.svg';
import { BarLink } from 'foundation/types';
import { getCurrentClientUser } from 'services/localStorageService';
import { getRequiredAuthoritiesForPath } from 'utils/authority.utility';

const SideBar: React.FC = () => {
  const [show, setShow] = useState(false);
  const styles = useThemeStyles();
  const userAuthorities = getCurrentClientUser()?.authorities || [];

  const toggleSidebar = () => setShow(prevShow => !prevShow);

  const hasRequiredAuthority = (item: BarLink): boolean => {
    const requiredAuthorities = getRequiredAuthoritiesForPath(item.link);
    return requiredAuthorities.length === 0 || requiredAuthorities.some(auth => userAuthorities.includes(auth));
  };

  return (
    <div className="trbewc-sidebar" style={styles.sidebar}>
      <>
        <button style={styles.buttonIconStyle} className="trbewc-button" onClick={toggleSidebar}>
          <svg className="trbewc-button__icon-menu" width={15} height={20}>
            <use href={sprite + '#icon-menu'}></use>
          </svg>
        </button>
        <Offcanvas style={styles.sidebarStyle} show={show} onHide={toggleSidebar}
                   className="trbewc-sidebar--left-border">
          <Offcanvas.Header style={styles.borderStyle} className="trbewc-sidebar__header">
            <div className="trbewc-header-logo">
              <Logo/>
            </div>
            <button style={styles.buttonCloseStyle} onClick={toggleSidebar}
                    className="trbewc-button trbewc-button-close">
              <svg className="trbewc-button__icon-close" width={13} height={13}>
                <use href={sprite + '#icon-close'}></use>
              </svg>
            </button>
          </Offcanvas.Header>
          <Offcanvas.Body className="trbewc-sidebar__body">
            <Nav className="trbewc-sidebar__nav flex-column">
              {sidebarConfig.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                  {section.map((item: BarLink, itemIndex) => (
                    hasRequiredAuthority(item) && (
                      <NavLink
                        key={itemIndex}
                        className="trbewc-sidebar__nav__nav-link"
                        to={item.link}
                        onClick={toggleSidebar}
                      >
                        <div className="trbewc-sidebar__nav__nav-padding">
                          <svg
                            style={styles.iconStyle}
                            className="trbewc-sidebar__nav__nav-icon"
                          >
                            <use href={sprite + item.icon}></use>
                          </svg>
                          {item.text}
                        </div>
                      </NavLink>
                    )
                  ))}
                  {sectionIndex < sidebarConfig.length - 1 && (
                    <div style={styles.dividerStyle}></div>
                  )}
                </React.Fragment>
              ))}
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    </div>
  );
};

export default SideBar;
