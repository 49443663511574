import React, { useEffect, useState } from 'react';
import useThemeStyles from 'hooks/useThemeStyles';
import { SplashScreenProps } from './types';
import { saveSplashScreen } from 'services/localStorageService';

const SplashScreen = ({ duration = 1000 }: SplashScreenProps) => {
  const [visible, setVisible] = useState(true);
  const styles = useThemeStyles();
  saveSplashScreen();

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration)

    return () => clearTimeout(timer)
  }, [duration]);

  if (!visible) {
    return null
  }

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1>ExchangesHub is loading...</h1>
      </div>
    </div>
  );
};

export default SplashScreen;
