import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes/router';
import { getSplashScreen, getUsername } from 'services/localStorageService';
import { AuthProvider } from 'contexts/AuthContext';
import { ThemeProvider } from 'contexts/ThemeContext';
import { WebSocketProvider } from 'contexts/WebSocketContext';
import SplashScreen from 'components/SplashScreen/SplashScreen';
import { NotificationProvider } from 'contexts/NotificationContext';
import NotificationInitializer from 'components/NotificationInitializer/NotificationInitializer';

const App: React.FC = () => {
  const showSplashScreen = getSplashScreen();
  const isUsername = getUsername();
  const isSplashScreenVisible = !showSplashScreen && !isUsername;

  return (
    <NotificationProvider>
      <ThemeProvider>
        <AuthProvider>
          <WebSocketProvider>
            <NotificationInitializer />
            {isSplashScreenVisible && <SplashScreen />}
            <RouterProvider router={router} />
          </WebSocketProvider>
        </AuthProvider>
      </ThemeProvider>
    </NotificationProvider>
  );
};

export default App;
