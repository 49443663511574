import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <div className="trbewc-not-found-container align-content-center text-center">
      <h2 className=" display-3">404</h2>
      <p>Page not found</p>
      <NavLink className="trbewc-not-found-container__link" to='/'>Go to Home</NavLink>
    </div>
  )
};

export default NotFoundPage;
