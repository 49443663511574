import React from 'react';
import moment from 'moment';
import { TimeAgoProps } from './types';

const TimeAgo: React.FC<TimeAgoProps> = ({ timestamp }) => {
  const timeago = () => {
    return moment(new Date(timestamp)).fromNow();
  };

  return (
    <div>
      last update {timeago()}
    </div>
  );
};

export default TimeAgo;
