import React, { useState } from 'react';
import useThemeStyles from 'hooks/useThemeStyles';
import 'assets/css/pages/sidebar-table-page.css';
import 'assets/css/components/dropdown.css';
import { DropdownProps } from './types';
import { Option } from 'foundation/types';
import { Dropdown as BootstrapDropdown, DropdownButton } from 'react-bootstrap';

const Dropdown: React.FC<DropdownProps> = ({
                                             label, options, title, menuStyle = '', headerStyle = '', onFilter = () => {}
                                           }) => {
  const styles = useThemeStyles();
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  // TODO [YYL] додати "All" -> tech1-framework

  const handleSelect = (key: string | null) => {
    if (key === null || key === 'all') {
      setSelectedOption(null);
      onFilter('');
    } else {
      const selected = options.find(option => option.id === key);
      if (selected) {
        setSelectedOption(selected);
        onFilter(key);
      }
    }
  };

  return (
    <BootstrapDropdown onSelect={handleSelect}>
      <DropdownButton
        variant="light"
        id="dropdown-basic-button"
        title={selectedOption ? selectedOption.label : label}
        className={`${headerStyle}`}
      >
        <BootstrapDropdown.Item eventKey="all">All</BootstrapDropdown.Item>
        {options.map((option, index) => (
          <React.Fragment key={index}>
            <BootstrapDropdown.Item eventKey={option.id}>{option.label}</BootstrapDropdown.Item>
            {index < options.length - 1 && (<div style={styles.dividerDropdownStyle}></div>)}
          </React.Fragment>
        ))}
      </DropdownButton>
    </BootstrapDropdown>
  );
};

export default Dropdown;
