import React, { useEffect, useState } from 'react';
import Table from 'components/Table/Table';
import invitesSessionsConfigs from './admin-invites-page-configs.json';
import { ResponseUserSession2 } from '../Settings/types';
import useThemeStyles from 'hooks/useThemeStyles';
import { useNotification } from 'contexts/NotificationContext';
import { getUnusedInvitationCodes } from 'api/framework';
import { getErrorMessage } from 'utils/errors.utility';
import useTableFilter from 'hooks/useTableFilter';
import TableControlPanel from 'components/TableControlPanel/TableControlPanel';

const AdministrationInvitesPage: React.FC = () => {
  const styles = useThemeStyles();
  const { showError } = useNotification();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false);
  const [invites, setInvites] = useState<ResponseUserSession2[]>([]);
  const [activeRowId, setActiveRowId] = useState<string | null>(null);

  const {
    filteredValues,
    handleFilter,
    activeFilter
  } = useTableFilter(invites, ['authorities']);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUnusedInvitationCodes();
        setInvites(response.data);
      } catch (error) {
        showError(getErrorMessage(error));
      }
    };
    fetchData();
  }, [showError]);

  const actionHandlers: { [key: string]: (row: any) => void } = {
    copy: (row) => {
      navigator.clipboard.writeText(row.value).then(() => {
        setActiveRowId(row.id);
        setTimeout(() => {
          setActiveRowId(null);
        }, 1000);
      });
    }
  };

  const actions = invitesSessionsConfigs.actions.map(action => ({
    label: action.label,
    handler: actionHandlers[action.type],
    icon: (row: any) => row.id === activeRowId ? 'icon-done' : action.icon
  }));

  const handleSearch = (results: any[]) => {
    setSearchResults(results);
    setSearchPerformed(true);
  };

  const resetSearch = () => {
    setSearchPerformed(false);
  };

  const searchableFields = invitesSessionsConfigs.headers
    .filter(header => header.searchable)
    .map(header => header.fieldName);

  const displayFields = invitesSessionsConfigs.headers.map(header => header.fieldName);

  const displayValues = searchPerformed ? searchResults : filteredValues;

  const searchConfigs = {
    onSearch: handleSearch,
    onReset: () => resetSearch(),
    values: invites,
    searchableFields,
    displayFields
  };

  const filterConfigs = {
    filterButtons: invitesSessionsConfigs.filterButtons,
    onFilter: handleFilter,
    isActive: activeFilter
  };

  return (
    <div className='trbewc-table-page'>
      <h4 className='trbewc-userbar-settings__title'>Invites</h4>
      <div style={styles.dividerStyle}></div>
      <TableControlPanel isFilterButtonsVisible={true} filterConfigs={filterConfigs} isSearchVisible={true} searchConfigs={searchConfigs}/>
      <div className='trbewc-table-page__body-container'>
        <Table
          headers={invitesSessionsConfigs.headers}
          values={displayValues}
          filterOptions={[]}
          sortOptions={[]}
          handleFilter={handleFilter}
          activeFilter={activeFilter}
          actions={actions}
        />
      </div>
    </div>
  );
};

export default AdministrationInvitesPage;
