import { createContext, useState, useEffect, ReactNode } from 'react';
import { ThemeContextType } from './types';
import { getTheme, saveTheme } from 'services/localStorageService';
import { getThemeVariables } from 'utils/css.utility';

const ThemeContext = createContext<ThemeContextType>({
  theme: 'light-theme',
  themeVariables: {
    primaryColor: '',
    secondaryColor: '',
    mainBgColor: '',
    secondaryBgColor: '',
    primaryTextColor: '',
    secondaryTextColor: '',
    linkColor: '',
    accentColor: '',
    primaryInputColor: '',
    primaryInputBorderColor: '',
    errorBGColor: '',
    iconHoverColor: '',
  },
  toggleTheme: () => {
  },
});

const lightTheme: 'light-theme' = 'light-theme';
const darkTheme: 'dark-theme' = 'dark-theme';

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<'light-theme' | 'dark-theme'>(() => {
    const savedTheme = getTheme();
    return savedTheme || darkTheme;
  });
  const [themeVariables, setThemeVariables] = useState(getThemeVariables(darkTheme));

  useEffect(() => {
    const savedTheme = getTheme();
    if (savedTheme) {
      setTheme(savedTheme);
      setThemeVariables(getThemeVariables(savedTheme));
    } else {
      const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const defaultTheme = prefersDarkScheme ? darkTheme : lightTheme;
      setTheme(defaultTheme);
      setThemeVariables(getThemeVariables(defaultTheme));
    }
  }, []);

  useEffect(() => {
    document.documentElement.className = theme;
    setThemeVariables(getThemeVariables(theme));
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === lightTheme ? darkTheme : lightTheme;
    setTheme(newTheme);
    saveTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, themeVariables, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;

